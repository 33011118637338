import React, { useState } from "react";

// utils
import { formatNumber } from "../../../utils";

// css
import * as styles from "./BookPackPilot.module.css";

const PlanCards = props => {
  const [planSelected, setPlanselected] = useState("annualPlan");

  const handlePlanSelection = val => {
    setPlanselected(val);
    props.handlePlan(val);
  };

  const extraAmount = props.extraAmount || 0;

  const annualOffer = Math.round(
    ((props.annualOfferPrice - props.annualPlanPrice) /
      props.annualOfferPrice) *
      100
  );
  const installmentOffer = Math.round(
    ((props.installmentOfferPrice - props.firstInstallmentPrice) /
      props.installmentOfferPrice) *
      100
  );
  const quarterlyOffer = Math.round(
    ((props.quarterlyOfferPrice - props.quarterlyPlanPrice) /
      props.quarterlyOfferPrice) *
      100
  );
  const monthlyOffer = Math.round(
    ((props.monthlyOfferPrice - props.monthlyPlanPrice) /
      props.monthlyOfferPrice) *
      100
  );

  return (
    <div className={`container ${styles.bookpackForm__radioCards}`}>
      <div className="row">
        {/* Annual Card */}
        <div
          className="col-12 col-md-4 col-lg-4"
          id="annualPlan"
          value="annualPlan"
          onClick={() => handlePlanSelection("annualPlan")}
          aria-hidden="true"
        >
          <div>
            <div
              className={
                planSelected === "annualPlan"
                  ? styles.bookpackForm__annCard
                  : styles.bookpackForm__unselectedCard
              }
            >
              {/* Radio Buttons */}
              <div className="row">
                <div className="col-12">
                  <div className={styles.bookpackForm__annCrd_para}>
                    <label
                      className={`custRadio ${
                        planSelected === "annualPlan"
                          ? styles.bookpackForm__lblTxt
                          : styles.bookpackForm__unselectLblTxt
                      }`}
                    >
                      <input
                        type="radio"
                        id="annualPlan"
                        name="plans"
                        value="annualPlan"
                        checked={planSelected === "annualPlan" ? true : false}
                      />
                      Pay annually
                      <span className="custRadioCheckround2"></span>
                    </label>
                  </div>
                </div>
              </div>
              {/* Amount */}
              <div className="row">
                <div className="col-12">
                  <div className={`${styles.bookpackForm_annCard_amt}`}>
                    <p
                      className={
                        planSelected === "annualPlan"
                          ? styles.bookpackForm__selectedDiv_para
                          : styles.bookpackForm__unselectedPara
                      }
                    >
                      {formatNumber(Math.round(props.annualPlanPrice / 12))}{" "}
                      <span>/ month</span>
                    </p>
                  </div>
                </div>
              </div>
              {/* Discount offer text */}
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center mt-4">
                    <p
                      className={
                        planSelected === "annualPlan"
                          ? styles.bookpackForm_discnOfferTxt_para
                          : styles.bookpackForm_discnOfferTxt_unSlectPara
                      }
                    >
                      MRP {formatNumber(props.annualOfferPrice)}
                    </p>
                    <div
                      className={`d-flex align-items-center ms-4 ${styles.bookpackForm_discnOfferTxt}`}
                    >
                      <span
                        className={`icon-coupon`}
                        style={{ fontSize: "20px" }}
                      ></span>
                      <p style={{ fontSize: "12px" }}>
                        {annualOffer}% Off -{" "}
                        {formatNumber(
                          props.annualOfferPrice - props.annualPlanPrice
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Offer Text */}
              <div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className={
                        planSelected === "annualPlan"
                          ? styles.bookpackForm_annCard_offrTxt
                          : styles.bookpackForm_annCard_unselectOffrTxt
                      }
                      data-testid="bookpack_annCard_btmTxt"
                    >
                      <p
                        className={
                          planSelected === "annualPlan"
                            ? styles.bookpackForm__selectedDiv_para
                            : styles.bookpackForm__unselectedPara
                        }
                        style={{ paddingBottom: "17px" }}
                      >
                        One time payment of{" "}
                        {formatNumber(Math.round(props.annualPlanPrice))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Installment Card */}
        <div
          className="col-12 col-md-4 col-lg-4"
          value="installmentPlan"
          onClick={() => handlePlanSelection("installmentPlan")}
          aria-hidden="true"
        >
          <div
            className={
              planSelected === "installmentPlan"
                ? styles.bookpackForm__annCard
                : styles.bookpackForm__unselectedCard
            }
          >
            {/* Radio Buttons */}
            <div className="row">
              <div className="col-12">
                <div className={styles.bookpackForm__annCrd_para}>
                  <label
                    className={`custRadio ${
                      planSelected === "installmentPlan"
                        ? styles.bookpackForm__lblTxt
                        : styles.bookpackForm__unselectLblTxt
                    }`}
                  >
                    <input
                      className="custom-control-input"
                      type="radio"
                      id="installmentPlan"
                      name="plans"
                      value="installmentPlan"
                      checked={
                        planSelected === "installmentPlan" ? true : false
                      }
                    />
                    Pay in half yearly installments
                    <span className="custRadioCheckround2"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* Amount */}
            <div className="row">
              <div className="col-12">
                <div className={`${styles.bookpackForm_annCard_amt}`}>
                  <p
                    className={
                      planSelected === "installmentPlan"
                        ? styles.bookpackForm__selectedDiv_para
                        : styles.bookpackForm__unselectedPara
                    }
                  >
                    {formatNumber(
                      Math.round(
                        ((props.firstInstallmentPrice + extraAmount) * 2) / 12
                      )
                    )}{" "}
                    <span>/ month</span>
                  </p>
                </div>
              </div>
            </div>
            {/* Discount offer text */}
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center mt-4">
                  <p
                    className={
                      planSelected === "installmentPlan"
                        ? styles.bookpackForm_discnOfferTxt_para
                        : styles.bookpackForm_discnOfferTxt_unSlectPara
                    }
                  >
                    MRP {formatNumber(props.installmentOfferPrice)}
                  </p>
                  <div
                    className={`d-flex align-items-center ms-4 ${styles.bookpackForm_discnOfferTxt}`}
                  >
                    <span
                      className={`icon-coupon`}
                      style={{ fontSize: "20px" }}
                    ></span>
                    <p style={{ fontSize: "12px" }}>
                      {installmentOffer}% Off -{" "}
                      {formatNumber(
                        props.installmentOfferPrice -
                          props.firstInstallmentPrice
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Offer Text */}
            <div>
              <div className="row">
                <div className="col-12">
                  <div
                    className={
                      planSelected === "installmentPlan"
                        ? styles.bookpackForm_annCard_offrTxt
                        : styles.bookpackForm_annCard_unselectOffrTxt
                    }
                    data-testid="bookpack_annCard_btmTxt"
                  >
                    <p
                      className={
                        planSelected === "installmentPlan"
                          ? styles.bookpackForm__selectedDiv_para
                          : styles.bookpackForm__unselectedPara
                      }
                    >
                      Installment of {formatNumber(props.firstInstallmentPrice)}{" "}
                      every 6 months. Total 2 installments in a year
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Quarterly Card */}
        {props.showQuarterly && (
          <div
            className="col-12 col-md-4 col-lg-4"
            value="quarterlyPlan"
            onClick={() => handlePlanSelection("quarterlyPlan")}
            aria-hidden="true"
          >
            <div>
              <div
                className={
                  planSelected === "quarterlyPlan"
                    ? styles.bookpackForm__annCard
                    : styles.bookpackForm__unselectedCard
                }
              >
                {/* Radio Buttons */}
                <div className="row">
                  <div className="col-12">
                    <div className={styles.bookpackForm__annCrd_para}>
                      <label
                        className={`custRadio ${
                          planSelected === "quarterlyPlan"
                            ? styles.bookpackForm__lblTxt
                            : styles.bookpackForm__unselectLblTxt
                        }`}
                      >
                        <input
                          className="custom-control-input"
                          type="radio"
                          id="quarterlyPlan"
                          name="plans"
                          value="quarterlyPlan"
                          checked={
                            planSelected === "quarterlyPlan" ? true : false
                          }
                        />
                        Pay in quarterly installments
                        <span className="custRadioCheckround2"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Amount */}
                <div className="row">
                  <div className="col-12">
                    <div className={`${styles.bookpackForm_annCard_amt}`}>
                      <p
                        className={
                          planSelected === "quarterlyPlan"
                            ? styles.bookpackForm__selectedDiv_para
                            : styles.bookpackForm__unselectedPara
                        }
                      >
                        {formatNumber(
                          Math.round((props.quarterlyPlanPrice * 4) / 12)
                        )}{" "}
                        <span>/ month</span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* Discount offer text */}
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center mt-4">
                      <p
                        className={
                          planSelected === "quarterlyPlan"
                            ? styles.bookpackForm_discnOfferTxt_para
                            : styles.bookpackForm_discnOfferTxt_unSlectPara
                        }
                      >
                        MRP {formatNumber(props.quarterlyOfferPrice)}
                      </p>
                      <div
                        className={`d-flex align-items-center ms-4 ${styles.bookpackForm_discnOfferTxt}`}
                      >
                        <span
                          className={`icon-coupon`}
                          style={{ fontSize: "20px" }}
                        ></span>
                        <p style={{ fontSize: "12px" }}>
                          {quarterlyOffer}% Off -{" "}
                          {formatNumber(
                            props.quarterlyOfferPrice - props.quarterlyPlanPrice
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Offer Text */}
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={
                          planSelected === "quarterlyPlan"
                            ? styles.bookpackForm_annCard_offrTxt
                            : styles.bookpackForm_annCard_unselectOffrTxt
                        }
                        data-testid="bookpack_annCard_btmTxt"
                      >
                        <p
                          className={
                            planSelected === "quarterlyPlan"
                              ? styles.bookpackForm__selectedDiv_para
                              : styles.bookpackForm__unselectedPara
                          }
                        >
                          Installment of{" "}
                          {formatNumber(Math.round(props.quarterlyPlanPrice))}{" "}
                          per quarter. Total 4 installments in a year
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`row ${styles.bookpackForm__newPlan}`}>
        {/* Monthly Card */}
        {props.showMonthly && (
          <div
            className="col-xs-12 col-sm-6 col-md-6 col-lg-6"
            value="monthlyPlan"
            onClick={() => handlePlanSelection("monthlyPlan")}
            aria-hidden="true"
          >
            <div
              className={
                planSelected === "monthlyPlan"
                  ? styles.bookpackForm__annCard
                  : styles.bookpackForm__unselectedCard
              }
            >
              {/* Radio Buttons */}
              <div className="row">
                <div className="col-12">
                  <div className={styles.bookpackForm__annCrd_para}>
                    <label
                      className={`custRadio ${
                        planSelected === "monthlyPlan"
                          ? styles.bookpackForm__lblTxt
                          : styles.bookpackForm__unselectLblTxt
                      }`}
                    >
                      <input
                        className="custom-control-input"
                        type="radio"
                        id="monthlyPlan"
                        name="plans"
                        value="monthlyPlan"
                        checked={planSelected === "monthlyPlan" ? true : false}
                      />
                      Pay in monthly installments
                      <span className="custRadioCheckround2"></span>
                    </label>
                  </div>
                </div>
              </div>
              {/* Amount */}
              <div className="row">
                <div className="col-12">
                  <div className={`${styles.bookpackForm_annCard_amt}`}>
                    <p
                      className={
                        planSelected === "monthlyPlan"
                          ? styles.bookpackForm__selectedDiv_para
                          : styles.bookpackForm__unselectedPara
                      }
                    >
                      {formatNumber(Math.round(props.monthlyPlanPrice))}{" "}
                      <span>/ month</span>
                    </p>
                  </div>
                </div>
              </div>
              {/* Discount offer text */}
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center mt-4">
                    <p
                      className={
                        planSelected === "monthlyPlan"
                          ? styles.bookpackForm_discnOfferTxt_para
                          : styles.bookpackForm_discnOfferTxt_unSlectPara
                      }
                    >
                      MRP {formatNumber(props.monthlyOfferPrice)}
                    </p>
                    <div
                      className={`d-flex align-items-center ms-4 ${styles.bookpackForm_discnOfferTxt}`}
                    >
                      <span
                        className={`icon-coupon`}
                        style={{ fontSize: "20px" }}
                      ></span>
                      <p style={{ fontSize: "12px" }}>
                        {monthlyOffer}% Off -{" "}
                        {formatNumber(
                          props.monthlyOfferPrice - props.monthlyPlanPrice
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Offer Text */}
              <div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className={
                        planSelected === "monthlyPlan"
                          ? styles.bookpackForm_annCard_offrTxt
                          : styles.bookpackForm_annCard_unselectOffrTxt
                      }
                      data-testid="bookpack_annCard_btmTxt"
                    >
                      <p
                        className={
                          planSelected === "monthlyPlan"
                            ? styles.bookpackForm__selectedDiv_para
                            : styles.bookpackForm__unselectedPara
                        }
                      >
                        Installment of{" "}
                        {formatNumber(Math.round(props.monthlyPlanPrice))} per
                        month. Total 12 installments in a year
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCards;
