import React from "react";

// components
import Carousel from "./Carousel";

//styles
import * as styles from "./SucessStories.module.css";

const SuccessStoriesV2 = props => {
  return (
    <div className={"bgWhiteColor"}>
      <div
        className={`container ${styles.sucessStoriesV1_container} bgWhiteColor`}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.sucessStoriesV1_heading}>
              Real <span style={{ color: "#F37920" }}>results</span> from real
              members
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <Carousel carouselId="successStoriesV1" showIndicators={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesV2;
