import React, { useState, useEffect } from "react";

// css
import * as styles from "./ToggleButton.module.css";

const ToggleButton = props => {
  const [toggleState, setToggleState] = useState("");

  useEffect(() => {
    if (props.doctorSpecializationArr)
      setToggleState(props.doctorSpecializationArr[0]);
  }, [props.doctorSpecializationArr]);

  const updateToggleButtonValue = e => {
    if (e.target.value) {
      setToggleState(e.target.value);
      props.handleToggleButton(e.target.value);
    }
  };

  const toggleButtonValArr = props.doctorSpecializationArr.map(item => {
    const key = item
      .replace("pilot_", "")
      .replace("_", "-")
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      key: key,
      value: item,
    };
  });

  return (
    <div className="container">
      <div>
        <div className={`row ${styles.toggleBtnSec}`}>
          {toggleButtonValArr.map(val => {
            return (
              <button
                onClick={updateToggleButtonValue}
                value={val.value}
                className={
                  toggleState && toggleState === `${val.value}`
                    ? `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__refer}`
                    : `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__track}`
                }
              >
                {val.key}
              </button>
            );
          })}
          {/* <button
            onClick={updateToggleButtonValue}
            value="pilot_diabetes"
            className={
              toggleState && toggleState === "pilot_diabetes"
                ? `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__refer}`
                : `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__track}`
            }
          >
            Diabetes
          </button>
          <button
            onClick={updateToggleButtonValue}
            value="pilot_obesity"
            className={
              toggleState && toggleState === "pilot_obesity"
                ? `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__refer}`
                : `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__track}`
            }
          >
            Obesity
          </button>
          <button
            onClick={updateToggleButtonValue}
            value="pilot_metabolic_wellness"
            className={
              toggleState && toggleState === "pilot_metabolic_wellness"
                ? `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__refer}`
                : `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__track}`
            }
          >
            Metabolic Wellness
          </button>
          <button
            onClick={updateToggleButtonValue}
            value="pilot_hypertension"
            className={
              toggleState && toggleState === "pilot_hypertension"
                ? styles.toggleBtn__refer
                : styles.toggleBtn__track
            }
          >
            Hypertension
          </button>
          <button
            onClick={updateToggleButtonValue}
            value="pilot_pcod"
            className={
              toggleState && toggleState === "pilot_pcod"
                ? styles.toggleBtn__refer
                : styles.toggleBtn__track
            }
          >
            PCOD
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
