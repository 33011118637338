import React, { Component } from "react";
import queryString from "query-string";

// seo
import Seo from "../../components/Seo";

// hook
import { useBookPackQuery } from "../../hooks/useBookPackQuery";
import { useWindowSize } from "../../hooks/useWindowSize";

// constant
import constant from "../../constants";

// utils
import { splitName, splitPlanCodeBookPack } from "../../utils";

// services
import { sendToCT } from "../../services/Clevertap";
import { sendToLSQ } from "../../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../../services/SendEmail";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import BookPackHeading from "../../components/DoctorLandingPage/BookPack/BookPackHeading";
import BookPackPilotForm from "../../components/DoctorLandingPage/BookPack/DoctorLpPilotForm";
import SuccessStoriesV2 from "../../components/Home/SuccessStories/SuccessStoriesV2";
import FooterTerms from "../../components/Footer/FooterTerms";

// api actions
import { handlePaymentOfBookPack } from "../../actions/bookPackActions";

class BookPackPilotDoctorLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      installmentPlanPrice: 0,
      firstInstallmentPrice: 0,
      quarterlyPlanPrice: 0,
      monthlyPlanPrice: 0,
      toggleButtonValue: "",
      medicalDisorderType: "DIABETES",
      annualOfferPrice: 0,
      installmentOfferPrice: 0,
      quarterlyOfferPrice: 0,
      monthlyOfferPrice: 0,
      doctorSpecializationArr: [],
      doctorName: "",
      indiaClinicId: 0,
      clientName: "",
      clientEmail: "",
      clientPhone: "",
      pageVisibleState: true, // handle to view page to user or not
      doctorEmail: "",
      doctorPhone: "",
    };
  }

  componentDidMount() {
    const annualPlan = this.props.bookPack.pilot_diabetes.annualPlan[0];
    const installmentPlan =
      this.props.bookPack.pilot_diabetes.installmentPlan[0];
    const quarterlyPlan = this.props.bookPack.pilot_diabetes.quarterlyPlan[0];
    const monthlyPlan = this.props.bookPack.pilot_diabetes.monthlyPlan[0];

    let plan = null;
    if (this.state.selectedPlan === "installmentPlan") {
      plan = installmentPlan;
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      plan = quarterlyPlan;
    } else if (this.state.selectedPlan === "monthlyPlan") {
      plan = monthlyPlan;
    } else {
      plan = annualPlan;
    }

    const annualPlanData = splitPlanCodeBookPack(annualPlan);
    const installmentPlanData = splitPlanCodeBookPack(installmentPlan);
    const quarterlyPlanData = splitPlanCodeBookPack(quarterlyPlan);
    const monthlyPlanData = splitPlanCodeBookPack(monthlyPlan);

    plan &&
      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        planDay: plan.planDay,
        productId: plan.productId,
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        annualOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.monthlyPlan[0].offerPrice
        ),
      });

    // Query Params Related To Doctor Details
    const {
      specialization,
      doctorName,
      clinicId,
      doctorEmail,
      doctorPhone,
      name,
      phone,
      email,
    } = queryString.parse(this.props.location.search);

    // Doctor Landing Page
    if (
      !specialization ||
      !doctorName ||
      !clinicId ||
      !doctorEmail ||
      !doctorPhone
    ) {
      // Make Page Content Hide For The Users
      this.setState({ pageVisibleState: false });
    } else {
      const arrVal = specialization.split(",");
      this.setState({
        doctorSpecializationArr: arrVal,
        doctorName: doctorName,
        toggleButtonValue: arrVal[0],
        indiaClinicId: clinicId,
        clientName: name,
        clientEmail: email,
        clientPhone: phone,
        doctorEmail: doctorEmail,
        doctorPhone: doctorPhone,
      });
    }
  }

  // Function -> To handle the payment of book pack
  handlePayNow = async values => {
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // util function -> to split the name
    const { firstName, lastName } = splitName(values.name);

    // leadSquared params
    let leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "book_pack_doctorLp_pilot",
      mx_Latest_Source: "New Website",
    };

    let subscriptionType = "";
    if (this.state.selectedPlan === "annualPlan") {
      subscriptionType = "YEARLY";
    } else if (this.state.selectedPlan === "installmentPlan") {
      subscriptionType = "HALF_YEARLY";
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      subscriptionType = "QUARTERLY";
    } else if (this.state.selectedPlan === "monthlyPlan") {
      subscriptionType = "MONTHLY";
    }

    // payment v3
    let paymentBody = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "B2B",
      couponCode: this.state.coupon ? this.state.coupon.toUpperCase() : "",
      languageList: languageArr,
      medicalDisorderType: this.state.medicalDisorderType,
      subscriptionType: subscriptionType,
      enrollingDoctorId: Number(this.state.indiaClinicId),
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
      subSourceType: "DrChannel"
    };

    try {
      this.setState({ loading: true });

      const results = await Promise.allSettled([
        handlePaymentOfBookPack(paymentBody),
        sendToLSQ(leadSquaredParams),
      ]);

      const response = results[0].value;
      const paymentUrl = response.results[0].url;

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated", {
        action: "BookPack_DoctorLp_Buy_Now Clicked",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        coupon: this.state.coupon,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error || {},
        "/marketing/v3/prospect"
      );

      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
    }
  };

  // This function executes when we apply coupon
  handleCouponUpdate = (planPrice, coupon, discountAmt) => {
    if (coupon) {
      this.setState({
        showConfetti: true,
        planPrice: Number(planPrice),
        coupon: coupon,
        couponApplied: true,
      });

      // update plan price of selected plan after coupon applied
      if (this.state.selectedPlan === "installmentPlan") {
        this.setState({
          installmentPlanPrice: Number(planPrice),
          firstInstallmentPrice:
            Number(this.state.firstInstallmentPrice) - Number(discountAmt),
        });
      } else if (this.state.selectedPlan === "quarterlyPlan") {
        this.setState({ quarterlyPlanPrice: Number(planPrice) });
      } else if (this.state.selectedPlan === "monthlyPlan") {
        this.setState({ monthlyPlanPrice: Number(planPrice) });
      } else {
        this.setState({ annualPlanPrice: Number(planPrice) });
      }

      setTimeout(
        function () {
          this.setState({ showConfetti: false });
        }.bind(this),
        5000
      );
    }
  };

  // This function executes when we remove the applied coupon
  removedCoupon = () => {
    const annualPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].annualPlan[0]
    );
    const installmentData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].installmentPlan[0]
    );

    const quarterlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].quarterlyPlan[0]
    );

    const monthlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].monthlyPlan[0]
    );

    if (this.state.selectedPlan === "installmentPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
      });
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
      });
    } else if (this.state.selectedPlan === "monthlyPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        monthlyPlanPrice: Number(monthlyPlanData[1]),
      });
    } else {
      this.setState({
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(annualPlanData[1]),
      });
    }
  };

  // Function -> Handle radio button plan cards
  handlePlan = val => {
    const annualPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].annualPlan[0]
    );
    const installmentData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].installmentPlan[0]
    );

    const quarterlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].quarterlyPlan[0]
    );

    const monthlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].monthlyPlan[0]
    );

    const selectedPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue][val][0]
    );

    let planCode = "";
    let planPrice = "";

    if (val === "installmentPlan") {
      planCode = installmentData[0];
      planPrice = Number(installmentData[1]);
    } else if (val === "quarterlyPlan") {
      planCode = quarterlyPlanData[0];
      planPrice = Number(quarterlyPlanData[1]);
    } else if (val === "monthlyPlan") {
      planCode = monthlyPlanData[0];
      planPrice = Number(monthlyPlanData[1]);
    } else {
      planCode = annualPlanData[0];
      planPrice = Number(annualPlanData[1]);
    }

    this.setState({
      selectedPlan: val,
      planCode: planCode,
      planPrice: planPrice,
      firstInstallmentPrice: Number(installmentData[2]),
    });

    // when coupon applied and user changes the plan, update plan price of previous plan selected to original plan price
    if (this.state.couponApplied === true && val === "annualPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });
    } else if (this.state.couponApplied === true && val === "installmentPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(annualPlanData[1]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });
    } else if (this.state.couponApplied === true && val === "quarterlyPlan") {
      this.setState({
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });
    } else {
      this.setState({
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        planCode: selectedPlanData[0],
      });
    }
  };

  // Handle toggle button functionality
  handleToggleButton = value => {
    // Handle price & plancode for Diabetes toggle button
    if (value === "pilot_diabetes") {
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "DIABETES",
        coupon: "",
        couponApplied: false,
        annualOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_obesity") {
      // Handle price & plancode for Obesity toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "OBESITY",
        coupon: "",
        couponApplied: false,
        annualOfferPrice: Number(
          this.props.bookPack.pilot_obesity.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_obesity.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_obesity.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_obesity.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_pre_diabetes") {
      // Handle price & plancode for Pre-Diabetes toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "PRE_DIABETES",
        coupon: "",
        couponApplied: false,
        annualOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_metabolic_wellness") {
      // Handle price & plancode for Pre-Diabetes toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "PRE_DIABETES",
        coupon: "",
        couponApplied: false,
        annualOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.installmentPlan[0]
            .offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.quarterlyPlan[0]
            .offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.monthlyPlan[0].offerPrice
        ),
      });
    }
  };

  render() {
    const {
      annualPlanPrice,
      loading,
      productId,
      planPrice,
      planDay,
      selectedPlan,
      serverError,
      installmentPlanPrice,
      firstInstallmentPrice,
      toggleButtonValue,
      quarterlyPlanPrice,
      monthlyPlanPrice,
      annualOfferPrice,
      installmentOfferPrice,
      quarterlyOfferPrice,
      monthlyOfferPrice,
      doctorSpecializationArr,
      doctorName,
      clientName,
      clientEmail,
      clientPhone,
      pageVisibleState,
    } = this.state;

    return (
      <div>
        <Seo
          title="Diabetes Reversal Program"
          description="Start your diabetes reversal journey. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />

        <HeaderWithLogo logoPosition="center" />

        {pageVisibleState ? (
          <div>
            {/* Heading */}
            <BookPackHeading />

            {/* Form */}
            <BookPackPilotForm
              price={planPrice}
              handlePayNow={this.handlePayNow}
              loading={loading}
              handleCouponUpdate={this.handleCouponUpdate}
              coupon={this.state.coupon}
              removedCoupon={this.removedCoupon}
              selectedPlan={selectedPlan}
              name={clientName}
              email={clientEmail}
              phone={clientPhone}
              locationSearch={this.props.location.search}
              productId={productId}
              annualPlanPrice={annualPlanPrice}
              planDay={planDay}
              showCouponSection={true}
              windowSize={this.props.windowSize}
              couponType="bookpackCoupon"
              leadSquaredParam="book_pack_pilot_doctorLp"
              handlePlan={this.handlePlan}
              installmentPlanPrice={installmentPlanPrice}
              firstInstallmentPrice={firstInstallmentPrice}
              handleToggleButton={this.handleToggleButton}
              toggleButtonValue={toggleButtonValue}
              quarterlyPlanPrice={quarterlyPlanPrice}
              monthlyPlanPrice={monthlyPlanPrice}
              annualOfferPrice={annualOfferPrice}
              installmentOfferPrice={installmentOfferPrice}
              quarterlyOfferPrice={quarterlyOfferPrice}
              monthlyOfferPrice={monthlyOfferPrice}
              couponMessageType="default"
              doctorSpecializationArr={doctorSpecializationArr}
              doctorName={doctorName}
            />
            <div className="text-center text-danger">{serverError} &nbsp;</div>

            <SuccessStoriesV2 />

            <div>
              <FooterTerms isHome={false} />
            </div>
          </div>
        ) : (
          <div>
            <p
              style={{
                textAlign: "center",
                paddingTop: "20%",
                fontSize: "32px",
              }}
            >
              Sorry{" "}
              <span role="img" aria-label="Pensive emoji">
                😔
              </span>{" "}
              Please visit this page through your Twin Doctor Portal Page
            </p>
          </div>
        )}
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayloadDiabetes = useBookPackQuery("doctor_lp_diabetes");
  const bookPackPayloadObesity = useBookPackQuery("doctor_lp_obesity");
  const bookPackPayloadPreDiabetes = useBookPackQuery("doctor_lp_pre_diabetes");
  const bookPackPayloadMetabolicWellness = useBookPackQuery(
    "doctor_lp_metabolic_wellness"
  );
  const bookPackPayloadPcod = useBookPackQuery("doctor_lp_pcod");
  const bookPackPayloadHypertension = useBookPackQuery("doctor_lp_obesity");
  const windowSize = useWindowSize();

  const finalBookPackPayload = {
    pilot_diabetes: bookPackPayloadDiabetes,
    pilot_obesity: bookPackPayloadObesity,
    pilot_pre_diabetes: bookPackPayloadPreDiabetes,
    pilot_metabolic_wellness: bookPackPayloadMetabolicWellness,
    pilot_pcod: bookPackPayloadPcod,
    pilot_hypertension: bookPackPayloadHypertension,
  };

  return (
    <BookPackPilotDoctorLandingPage
      {...props}
      bookPack={finalBookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
