import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./BookPack.module.css";

const ProgramIncludes = () => {
  return (
    <div className={`container ${styles.programIncludes__cont}`}>
      <div className="row">
        <div className={`col-12 d-flex justify-content-center ${styles.programIncludes__hdng}`}>
          <h3>Program Includes</h3>
        </div>
        </div>
        <div className={`row ${styles.programIncludes__rowCard}`}>
          <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 ${styles.programIncludes__col}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.programIncludes__col1}`}>
              {/* image */}
              <div className={styles.programIncludes__col1__img}>
                <StaticImage
                  src="../../images/bookPack/bp_icn1.png"
                  alt="bookpack"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              {/* para */}
              <div className={styles.programIncludes__col1__para}>
                <p>Mobile App from Twin Health with health insights</p>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 ${styles.programIncludes__col}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.programIncludes__col2}`}>
              {/* image */}
              <div className={styles.programIncludes__col1__img}>
                <StaticImage
                  src="../../images/bookPack/bp_icn2.png"
                  alt="bookpack"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              {/* para */}
              <div className={styles.programIncludes__col1__para}>
                <p>Whole Body Digital Twin Technology ( by AI & ML)</p>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 ${styles.programIncludes__col}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.programIncludes__col3}`}>
              {/* image */}
              <div className={styles.programIncludes__col1__img}>
                <StaticImage
                  src="../../images/bookPack/bp_icn3.png"
                  alt="bookpack"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              {/* para */}
              <div className={styles.programIncludes__col1__para}>
                <p>Care Team Services: (Health Coach & Doctors)</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ProgramIncludes;
