import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// styles
import * as styles from "./Header.module.css";

const HeaderWithLogo = props => {
  return (
    <>
      <div
        className={`${styles.headerWrapper} ${
          !props.isHome && "whiteBgWrapper"
        } `}
        id="topHeader"
      >
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-light ${
            props.background === "white" ? styles.navBar3 : styles.navBar2
          }
          `}
        >
          <div
            className={`container ${
              props.logoPosition === "center" ? styles.centerLogo : null
            }`}
          >
            <div className="navbar-brand">
              <Link to={props.logoOnClickRedirect}>
                {props.background === "white" ? (
                  <StaticImage
                    src="../../images/twin-logo/twinLogo2_2024.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    className={styles.logoImage}
                  />
                ) : (
                  <StaticImage
                    src="../../images/twin-logo/twinLogo3_2024.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    className={styles.logoImage}
                  />
                )}
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderWithLogo;

// NOTE
// props.logoPosition = "center" then logo will be placed to center and if value is null then it will positioned in left
// logoOnClickRedirect = url for redirection. For Ex:- logoOnClickRedirect="/"
