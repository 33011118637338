import React from "react";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";

// components
import CouponForm from "./CouponForm";

// css
import * as styles from "./BookPackPilot.module.css";

const CouponModal = props => {
  return (
    <>
      {props.windowSize && props.windowSize.width <= 768 ? (
        <Offcanvas
          show={props.showCanvasModal}
          placement="bottom"
          style={{ height: "275px" }}
        >
          <Offcanvas.Header className={styles.bookpackForm_modal_header}>
            <div
              onClick={props.onHideCanvasModal}
              className={styles.bookpackForm_modal_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body
            className={styles.bookpackForm_modal_termsBdy}
            style={{ background: "#FFFFFF" }}
          >
            <CouponForm
              handleCouponUpdate={props.handleCouponUpdate}
              coupon={props.coupon}
              handleSuccessMsg={props.handleSuccessMsg}
              selectedPlan={props.selectedPlan}
              productId={props.productId}
              couponType={props.couponType}
              toggleButtonValue={props.toggleButtonValue}
              planPrice={props.planPrice}
              couponMessageType={props.couponMessageType}
            />
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Modal
          show={props.show}
          onHide={props.onHide}
          backdrop="static"
          centered={true}
          size="md"
        >
          <Modal.Header className={styles.bookpackForm_modal_header}>
            <div
              onClick={props.onHide}
              className={styles.bookpackForm_modal_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Modal.Header>
          <Modal.Body style={{ background: "#FFFFFF" }}>
            <CouponForm
              handleCouponUpdate={props.handleCouponUpdate}
              coupon={props.coupon}
              handleSuccessMsg={props.handleSuccessMsg}
              selectedPlan={props.selectedPlan}
              productId={props.productId}
              couponType={props.couponType}
              toggleButtonValue={props.toggleButtonValue}
              annualPlanPrice={props.annualPlanPrice}
              firstInstallmentPrice={props.firstInstallmentPrice}
              quarterlyPlanPrice={props.quarterlyPlanPrice}
              monthlyPlanPrice={props.monthlyPlanPrice}
              planPrice={props.planPrice}
              couponMessageType={props.couponMessageType}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CouponModal;
